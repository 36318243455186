// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    clientId: '9227dfd5-4cac-4490-b301-44a93bc0c1bc',
    clientSecret: 'wDKTLYkuTVbdJmHHyCGaNqer2kapMapXKIJ9sFeH',
    apiUrl: 'https://payments.payments.dev.zenzenzen.net',
    authTokenKey: 'access_token',
    currentSiteName: 'Payments',
    rollbarId: '81b372fb67b04079b57d879c4e6b60cc', // От слиент ид
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
