import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {NgxPermissionsModule} from 'ngx-permissions';
import {TreoModule} from '@treo';
import {TreoConfigModule} from '@treo/services/config';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import { RollbarService, rollbarFactory} from './shared/rollbar/rollbar';
import {GravatarModule} from 'ngx-gravatar';
import {WINDOW_PROVIDERS} from './core/utils/window.provider';

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
};

// TODO вернуть роллбар

@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
        // { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        WINDOW_PROVIDERS
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // Permissions
        NgxPermissionsModule.forRoot(),
        RouterModule.forRoot(appRoutes, routerConfig),

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),

        GravatarModule.forRoot({}),
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
