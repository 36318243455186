import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[appCheckScroll]'
})
export class CheckScrollDirective {

    @Output() scrollEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    @HostListener('scroll', ['$event'])

    onScroll($event): void {
        $event.preventDefault();
        this.scrollEmit.emit(true);
    }
}
