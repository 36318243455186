import {Component, OnInit, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-scroll-to',
    templateUrl: './scroll-to.component.html',
    styleUrls: ['./scroll-to.component.scss']
})
export class ScrollToTopComponent implements OnInit, AfterViewInit {

    @Input() scrollElement: HTMLDivElement;
    @Input() scrollEvent: any;
    windowScrolled: boolean;

    isScrollTop = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef) {
    }


    scroll(): void {
        const l = this.scrollElement;
        if (l.scrollTop) {
            const scrollPercent = l.scrollTop * 100 / (l.scrollHeight - l.clientHeight);
            this.isScrollTop = scrollPercent > 50;
        } else {
            this.isScrollTop = false;
        }
        this._changeDetectorRef.markForCheck();
    }

    ngOnInit(): void {
        this.scrollEvent
            .pipe(
                debounceTime(100)
            )
            .subscribe(() => {
                this.windowScrolled = this.scrollElement.scrollHeight !== this.scrollElement.clientHeight;
                this.scroll();
            });
    }

    ngAfterViewInit(): void {
        this.windowScrolled = this.scrollElement.scrollHeight !== this.scrollElement.clientHeight;
    }

    scrollTo(): void {
        if (this.isScrollTop) {
            this.scrollElement.scroll(0, 0);
        } else {
            this.scrollElement.scroll(0, this.scrollElement.scrollHeight);
        }
    }

}
