// When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import {environment} from '../../../environments/environment';
import {
    Injectable,
    Inject,
    InjectionToken,
    ErrorHandler
} from '@angular/core';

import Rollbar from 'rollbar';

const rollbarConfig = {
    accessToken: environment.rollbarId,
    captureUncaught: environment.production,
    captureUnhandledRejections: environment.production,
    enabled: environment.production
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {
    }

    handleError(err: any): void {
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory(): any {
    return new Rollbar(rollbarConfig);
}