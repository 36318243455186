import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

    show: string | boolean = null;


    constructor(private location: Location) {
    }

    ngOnInit(): void {
        this.show = window.history.length && window.history.length > 1;
    }

    back(): void {
        this.location.back();
    }
}
