import {Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ViewImageComponent} from '../view-image/view-image.component';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    private url = environment.apiUrl;

    @Input() fileForm;
    @Input() emitter;
    @Input() field = 'cover';

    imageURL = '';
    loader = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef,
                private _httpClient: HttpClient,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.imageURL = this.fileForm.get(this.field).value;

        if (this.emitter) {
            this.emitter.subscribe(() => {
                this.imageURL = this.fileForm.get(this.field).value;
            });
        }
    }

    saveImage(event: any): void {
        this.loader = true;
        const file = (event.target as HTMLInputElement).files[0];
        this.uploadFile(file).subscribe(res => {
            if (res.data && res.data.file) {
                this.imageURL = res.data.file.storage_url + '/' + res.data.file.storage_file_path;
                this.fileForm.get(this.field).patchValue(this.imageURL);
                this.fileForm.get(this.field).updateValueAndValidity();
            }
            this.loader = false;
            this._changeDetectorRef.markForCheck();
        });
    }

    deleteImage(): void {
        this.imageURL = '';
        this.fileForm.get(this.field).setValue('');
        this._changeDetectorRef.markForCheck();
    }

    uploadFile(file: any): any {
        const data = new FormData();
        data.append('file', file);
        return this._httpClient.post(this.url + '/api/uploads/images', data);
    }

    viewImage(image: string): void {
        this.dialog.open(ViewImageComponent, {
            data: image
        });
    }

}
