import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-view-image',
    templateUrl: './view-image.component.html'
})
export class ViewImageComponent implements OnInit {

    imageURL = '';

    constructor(public dialogRef: MatDialogRef<ViewImageComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.imageURL = this.data;
    }


    close(): void {
        this.dialogRef.close();
    }
}
