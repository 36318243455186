<div class="flex flex-col w-full">
<!--    <ng-container *ngIf="!loader">-->
        <img [src]="imageURL" alt="image-url">
        <button type="button"
                mat-raised-button
                color="warn"
                (click)="close()">
            Close
        </button>
<!--    </ng-container>-->

<!--    <mat-progress-spinner *ngIf="loader"-->
<!--                          [diameter]="48"-->
<!--                          [mode]="'indeterminate'"></mat-progress-spinner>-->
</div>