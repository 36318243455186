import {
    backendNavigation,
} from './core/config/app.config';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {StorageUtilsService} from './core/utils/storage.utils.service';

import {UserService} from './layout/common/user/user.service';
import {AuthService} from './core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param _router
     * @param _userService
     * @param _authService
     * @param _storageUtilsService
     */
    constructor(
        private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private _storageUtilsService: StorageUtilsService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any> {
        const isUser = this._userService.user && this._userService.user.id;
        if (localStorage.getItem(environment.authTokenKey) && !isUser) {
            return this._userService.loadUser();
        } else {
            return of(false);
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            // User
            this._loadUser(),
        ]).pipe(
            map(() => {
                return {
                    navigation: {
                        default: backendNavigation,
                        horizontal: backendNavigation
                    }
                };
            })
        );
    }
}
