import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableUtilsService} from '../../../core/utils/table-utils.service';
import {StorageUtilsService} from '../../../core/utils/storage.utils.service';

@Component({
    selector: 'app-view-message',
    templateUrl: './view-message.component.html',
    styleUrls: ['./view-message.component.scss']
})
export class ViewMessageComponent implements OnInit, OnDestroy {

    @Input() message: any = null;

    constructor(public _tableUtilsService: TableUtilsService,
                private _storageUtilsService: StorageUtilsService) {
    }

    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }


}
