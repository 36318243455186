import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {ViewMessageComponent} from './view-message.component';
import {ViewAttachmentsModule} from '../view-attachments/view-attachments.module';
import {GravatarModule} from 'ngx-gravatar';

@NgModule({
    declarations: [
        ViewMessageComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        ViewAttachmentsModule,
        GravatarModule,
    ],
    exports: [
        ViewMessageComponent
    ]
})
export class ViewMessageModule {
}
