import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { UserModule} from '../../../common/user/user.module';
import { SharedModule} from '../../../../shared/shared.module';
import { FuturisticLayoutComponent } from './futuristic.component';
import {TreoNavigationModule} from '../../../../../@treo/components/navigation';

@NgModule({
    declarations: [
        FuturisticLayoutComponent
    ],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        UserModule,
        SharedModule,
        TreoNavigationModule,
        MatProgressBarModule
    ],
    exports     : [
        FuturisticLayoutComponent
    ]
})
export class FuturisticLayoutModule
{
}
