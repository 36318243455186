<form class="flex flex-col w-full"
      [formGroup]="fileForm">
    <div class="w-full flex align-items-center">
        <!--        <div class="imagePreview col-lg-12" *ngIf="imageURL && imageURL !== ''">-->
        <!--            <img [src]="imageURL" class="w-100" [alt]="userForm.value.name">-->
        <!--        </div>-->
        <ng-container *ngIf="!imageURL; else imagePath">
            <input type="file" class="custom-file-input d-none" id="customFileLang"
                   accept="image/*" (change)="saveImage($event)"/>
            <label class="custom-file-label w-2/4 position-relative"
                   for="customFileLang">Choose file</label>
            <mat-progress-spinner *ngIf="loader"
                                  [diameter]="24"
                                  [mode]="'indeterminate'"></mat-progress-spinner>
        </ng-container>
        <ng-template #imagePath>
            <div class="flex align-items-center justify-content-between">
                <img [src]="imageURL" 
                     alt="image-url" class="small-image" 
                     (click)="viewImage(imageURL)">
                <button type="button"
                        mat-raised-button
                        class="my-5"
                        color="warn"
                        (click)="deleteImage()">
                    Delete image
                </button>
            </div>
        </ng-template>
    </div>
</form>