import {Layout} from 'app/layout/layout.types';

import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */
export const appConfig: AppConfig = {
    layout: 'centered',
    theme: 'light'
};

export const backendNavigation: any[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_outline:dashboard',
        link: '/backend/dashboard',
    },
    {
        id: 'sites',
        title: 'Sites',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/backend/sites',
        permission: PERMISSIONS.SITES.VIEW
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'mat_outline:people_outline',
        link: '/backend/users',
        permission: PERMISSIONS.USERS.VIEW
    },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'mat_outline:list',
        link: '/backend/roles',
        permission: PERMISSIONS.ROLES.VIEW
    },

];
