import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {ActionNotificationComponent} from './action-notification.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        ActionNotificationComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatIconModule
    ],
    providers: [
    ],
    exports: [
        ActionNotificationComponent
    ]
})
export class ActionNotificationModule {
}
