import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CustomSelectComponent} from './custom-select.component';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        CustomSelectComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatIconModule,
    ],
    providers: [
        MatSelect,
    ],
    exports: [
        CustomSelectComponent
    ]
})
export class CustomSelectModule {
}
