import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-view-attachments',
    templateUrl: './view-attachments.component.html',
    styleUrls: ['./view-attachments.component.scss']
})
export class ViewAttachmentsComponent implements OnInit, OnDestroy {

    @Input() currentAttachments: any = null;
    @Input() forCustomField: boolean = null;
    @Input() showDelete: boolean = true;

    _unsubscribeAll = new Subject();

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    deleteAttachments(index: number, array: any = []): void {
        if (array && array.length) {
            array.splice(index, 1);
        }
    }

}
