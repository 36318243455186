import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {BackButtonComponent} from './back-button.component';

@NgModule({
    declarations: [
        BackButtonComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [
        BackButtonComponent
    ]
})
export class BackButtonModule {
}
