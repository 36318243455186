<ng-container *ngIf="show">
    <div class="back">
        <a mat-icon-button
           (click)="back()">
            <span class="mat-button-wrapper">
                <mat-icon
                        [svgIcon]="'arrow_back'"></mat-icon>
            </span>
        </a>
    </div>
</ng-container>
