// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ScrollToTopComponent} from './scroll-to.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
    declarations: [
        ScrollToTopComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [
        ScrollToTopComponent
    ]
})
export class ScrollToModule {
}
