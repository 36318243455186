<!-- Navigation -->
<treo-vertical-navigation class="bg-indigo-800 theme-dark"
                          [appearance]="'futuristic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <img src="assets/images/logo/logo.png">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <user></user>
    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        {{currentSiteName}} &copy; {{currentYear}}
    </div>

</div>
