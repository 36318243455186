import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {TextEditorComponent} from './text-editor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JoditAngularModule} from 'jodit-angular';

@NgModule({
    declarations: [
        TextEditorComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        JoditAngularModule
    ],
    providers: [
    ],
    exports: [
        TextEditorComponent
    ]
})
export class TextEditorModule {
}
