import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {ViewAttachmentsComponent} from './view-attachments.component';

@NgModule({
    declarations: [
        ViewAttachmentsComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [
        ViewAttachmentsComponent
    ]
})
export class ViewAttachmentsModule {
}
